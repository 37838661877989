//#region IMPORTS:
import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { Component, computed, inject, input, InputSignal, output, signal, Signal, WritableSignal, AfterViewInit, Output, EventEmitter, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { tap, combineLatest, delay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BundleItems, PreOrderedProduct, Product, ProductAttributeValues, ProductPackages } from '../../product/product.types';
import { SubscriptionsService } from '../../settings/account/subscriptions/subscriptions.service';
import { Subscription, SubscriptionPayload } from '../../settings/account/subscriptions/subscriptions.types';
import { StockService } from '../../stock/stock.service';
import { arrayToMapMultiKey, sanitizeNumericInput, unableOperationMessage, isAddressUpdateRequired, isNoAddressUser, isSuspendedUser, arrayToMap, isANoDeliveryDayUser, isANoPaymentMethodUser, trackAddToCartEvent } from '../common/utils';
import { ModalContentTypes } from '../constants/modal-content-types';
import { LocalStorageService } from '../local-storage.service';
import { ModalContentData } from '../modal-content/modal-content';
import { ModalContentService } from '../modal-content/modal-content.service';
import { NotificationService } from '../notification/notification.service';
import { OrderService } from '../order.service';
import { CardTypes, FavOrDislike, ProductCard, ProductCardResume } from '../product-card/product-card.types';
import { SignalsStoreService } from '../signals-store.service';
import { Item } from '../types/common.types';
import { BundleModifyTypes } from '../types/flows-config.types';
import { BundleEditionTypes, FireBaseProductOrder } from '../types/order.type';
import { ProductCardV2SummaryComponent } from './product-card-v2-summary/product-card-v2-summary.component';
import { ProductCardV2MainComponent } from './product-card-v2-main/product-card-v2-main.component';
import { ProductCardV2NotifyComponent } from './product-card-v2-notify/product-card-v2-notify.component';
import { ProductCardV2NotifiedComponent } from './product-card-v2-notified/product-card-v2-notified.component';
import { ProductCardV2SubscribeComponent } from './product-card-v2-subscribe/product-card-v2-subscribe.component';
import { ProductCardV2Service } from './product-card-v2.service';
import { ProductCardV2ResumeComponent } from "./product-card-v2-resume/product-card-v2-resume.component";
import { formatDateToReadableString } from '../utils/formatting';
import { ProductsService } from '../../product/products.service';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../constants/databases';
import { FirebaseCrudService } from '../firebase-crud.service';
import { ProductCardV2PreferencesComponent } from './product-card-v2-preferences/product-card-v2-preferences.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ResolutionService } from '../resolution.service';
import { ProductCardV2MobileComponent } from './product-card-v2-mobile/product-card-v2-mobile.component';
import { Session } from '../types/session.type';
import { ProductCardV2ResumeMobileComponent } from './product-card-v2-resume-mobile/product-card-v2-resume-mobile.component';
import { DATA_LAYER_ECOMMERCE_CURRENCY_OPTIONS } from '../data-layer/data-layer.types';
import { DataLayerService } from '../data-layer/data-layer.service';
//#endregion

@Component({
  selector: 'app-product-card-v2',
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgbModule,
    ProductCardV2SummaryComponent,
    ProductCardV2MainComponent,
    ProductCardV2NotifyComponent,
    ProductCardV2NotifiedComponent,
    ProductCardV2SubscribeComponent,
    ProductCardV2ResumeComponent,
    ProductCardV2PreferencesComponent,
    ProductCardV2MobileComponent,
    ProductCardV2ResumeMobileComponent
  ],
  providers: [{
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.apis.google.places,
      libraries: ['places'],
      region: 'US',
    }),
  }, SubscriptionsService],
  templateUrl: './product-card-v2.component.html',
  styleUrl: './product-card-v2.component.scss'
})
export class ProductCardV2Component implements AfterViewInit, OnInit {

  //#region Injections
  #location = inject(Location)
  #notificationService = inject(NotificationService);
  #localStorageService = inject(LocalStorageService);
  #stockService = inject(StockService);
  #signalsStoreService = inject(SignalsStoreService);
  #modalService = inject(ModalContentService);
  #router = inject(Router);
  #orderService = inject(OrderService);
  #subscriptionsService = inject(SubscriptionsService);
  #activeModal = inject(NgbModal);
  #productCardV2Service = inject(ProductCardV2Service);
  #productsService = inject(ProductsService);
  #firebaseCrudService = inject(FirebaseCrudService);
  #sanitizer = inject(DomSanitizer);
  #resolutionService = inject(ResolutionService);
  #dataLayerService = inject(DataLayerService);
  //#endregion

  //#region Inputs
  cardResume: InputSignal<ProductCardResume> = input.required();
  isFromWelcome = input<boolean>(false);
  isFromBlog = input(false);
  isGiftCard: InputSignal<boolean> = input(false);

  isFromShop: InputSignal<boolean | undefined> = input();
  #isFromShop$ = toObservable(this.isFromShop);
  isSignupFlow = input(false, { alias: 'isSignupFlow' });
  isPreference = input<boolean>(false);
  subscribeAndcustomizeBox = input<boolean>(false);
  isFromFavPage = input<boolean>(false);
  //#endregion

  //#region Outputs
  onComponentReady = output();
  outSelectedVariant = output<any>({ alias: 'selectedVariant' });
  outShowDiscountTag = output<boolean>();
  onPurchaseGiftCardClicked = output();
  outClickFavoriteOrDislike = output<FavOrDislike>();
  @Output() farmBoxCard: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  //#region Normal properties
  allVariantsOutOfStock = false;
  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;
  cardType = signal(CardTypes.resume);
  cardTypes = CardTypes;
  defaultCard: Partial<ProductCardResume> | any = {
    product: undefined,
    settings: {
      hasStock: false,
      showAttributes: true
    }
  }
  hasNotify = false;
  productAttributes: any[] = [];
  wasValueHigher10!: boolean;

  #variant: any = null;
  #bundle: any = null;
  //#endregion

  //#region Computed Values
  availableQuantities = computed(() => this.#setUpAvailableQuantities());
  existsInOrder: Signal<{ common: boolean, subscription: boolean, totalQuantity: number }> = computed(() => this.#existsInOrder());
  firebaseOrder = computed(() => this.#signalsStoreService.firebaseOrder() || null);
  isDisabledActionButtons = computed(() => this.shouldDisableActionButtons());
  isDisabledSubscribeButton = computed(() => this.shouldDisableSubscribeActionButtons());
  isOutOfStockPerQuantity = computed(() => this.stockValidation() && this.hasStockValidationPerQuantity());
  isPreOrderedVariant = computed(() => this.#setUpIsPreOrderedVariant());
  marketStatus = computed(() => this.#signalsStoreService.marketStatus());
  odooOrder = computed(() => this.#orderService.odooOrder() || null);
  selectedAttributeCardConfig = computed(() => this.#setUpSelectAttributeConfig())
  stockValidation = computed(() => this.#isBundleOutOfStock() || this.setUpStockForProductVariant());
  storedSubscriptions = computed(() => this.#signalsStoreService.subscriptions() || []);
  totalStockAvailable = computed(() => this.#setUpTotalStockAvailable());
  isDislike = computed(() => this.#checkDislikeProduct());
  isDisabledOverProductActions = computed(() => this.#shouldDisableOverProductActions());
  skippedSubscriptionLineId = computed(() => this.#setUpIsSkippedSubscriptionVariant());
  isFavorite = computed(() => this.#checkFavProduct())
  isMobile = computed(() => this.#resolutionService.isMobile());
  shouldShowMobileCard = computed(() => this.#shouldShowMobileCard())
  //#endregion

  //#region Signals
  card: WritableSignal<ProductCard> = signal(this.defaultCard);
  hasStockValidationPerQuantity: WritableSignal<boolean> = signal(false);
  isOutOfStock: WritableSignal<boolean> = signal(false);
  initiateSubscriptionImmediatly: WritableSignal<boolean> = signal(true);
  mapBundleStockSignal: WritableSignal<Map<number | string, any>> = signal(new Map());
  newQuantity: WritableSignal<any> = signal(1);
  preOrders: WritableSignal<PreOrderedProduct[]> = signal([]);
  product: WritableSignal<Product | undefined> = signal(undefined);
  productId: WritableSignal<number> = signal(0);
  selectedAttribute: WritableSignal<any> = signal('')
  selectedPackage: WritableSignal<any> = signal('')
  selectedQuantity: WritableSignal<any> = signal(1);
  stock: WritableSignal<Map<string | number, any>> = signal(new Map());
  selectedFrequency: WritableSignal<Item | undefined> = signal(undefined);
  selectedDateSubscription: WritableSignal<string | undefined> = signal(undefined);
  #justAddedProduct = this.#signalsStoreService.justAddedProduct;
  updateLikeDislike = signal(false);
  canEmitChangeLike = false;
  canEmitChangeDislike = false;
  isFirstTime = true;
  minDate: WritableSignal<Date> = signal(new Date());
  deliveryDay: WritableSignal<string> = signal('');
  isInShopComponent: Signal<boolean> = computed(() => this.#signalsStoreService.getIsInShopComponent());
  //#endregion

  //#region Observables
  #stockValidation$ = toObservable(this.stockValidation);
  #hasStockValidationPerQuantity$ = toObservable(this.hasStockValidationPerQuantity);
  //#endregion

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    const session: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (session?.deliveryInfo) {
      this.deliveryDay.set(session.deliveryInfo.deliveryDay);
      const [year, month_str, day] = session.deliveryInfo.deliveryDate.split('-');
      const month = (+month_str) - 1;
      const aux_date = new Date(+year, month, (+day + 1));
      this.minDate.set(new Date(aux_date));
    }
  }


  ngAfterViewInit(): void {
    this.onComponentReady.emit();

    if (this.isFromShop() === undefined) {
      this.#cardResumeTransform();
    }

    this.#isFromShop$.subscribe(
      (value: boolean | undefined) => {
        if (value === true) {
          this.#cardResumeTransform();
        }
      }
    );

  }

  //#region Methods
  onKeyUp(event: any): void {
    const { value } = event?.target

    if (+ value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+value)
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputQuantityChange(event: any) {
    const { value } = event?.target
    this.wasValueHigher10 = +value >= 10;
  }

  onQuantityChange(target: any) {
    if (+target?.value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+target?.value);
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputChange(event: any) {
    const newValue = +sanitizeNumericInput(event);
    this.selectedQuantity.set(newValue);
    event.target.value = newValue;
  }

  onAttributeChange(attrId: number, target: any) {
    const valueId = target.value
    let attributeSelected: any;
    for (const attribute of this.product()?.attributes as any) {
      if (!attributeSelected && attribute.id === attrId) {
        const value = attribute.values?.find((value: any) => value.id === valueId);
        if (value) value.hasDiscount = !!value.originalPrice;
        attributeSelected = {
          id: attribute.id,
          name: attribute.name,
          value
        }
        break;
      }
    }

    this.selectedAttribute.set(attributeSelected);
    if (attributeSelected.value && this.card().settings?.isSummary) this.#trackPackOrAttrItemViewDataLayerEvent({ value: attributeSelected.value });
  }

  onPackageChanged(value: string | number) {
    let packageSelected: any;
    packageSelected = this.product()?.packages?.find((pkg: any) => pkg.id === value)
    this.selectedPackage.set(packageSelected);
    if (packageSelected && this.card().settings?.isSummary) this.#trackPackOrAttrItemViewDataLayerEvent({ pack: packageSelected });
  }

  async addProductToCart(isSubscription: boolean = false, event: any) {
    // If the user has not delivery day:
    if (isANoDeliveryDayUser(this.#localStorageService, this.#modalService)) return;
    // If the user has not payment method (abandoned user):
    if (isANoPaymentMethodUser(this.#localStorageService, this.#modalService, this.#activeModal)) return;
    // If the should update their address:
    if (isAddressUpdateRequired(this.#localStorageService, this.#modalService, this.#activeModal, this.#router)) return;
    // If the user does not has address:
    if (isNoAddressUser(this.#localStorageService, this.#modalService, this.#activeModal, this.#router)) return;

    // If the product is available for Pre-Order:
    if (this.product()?.preOrder) {
      this.#openModalPreOrderProduct();
      return;
    };
    // If the user has their order marked as skipped, prevent the full process:
    const hasSkippedOrder = await this.#hasSkippedOrder();
    if (hasSkippedOrder) return;

    // If it is a skipped subscription, we should to unskip it:
    if (isSubscription && this.skippedSubscriptionLineId()) return this.#openModalUnskipLine();

    // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
    if (this.#isCustomBundleModificationType(isSubscription)) return;

    // If it's a subscription it's not necessary to validate and get variant and bundle
    // because it was already validated and configured at flipCardType method:
    if (!isSubscription && !this.validateProductAndSetUpVariant()) return;

    /*
    If the user did not want to start the subscription immediatly, we should to save the subscription directly.
    This subscription shouldn't be added to the  cart or the order:
    */
    if (this.selectedFrequency() && (!this.initiateSubscriptionImmediatly() || isSubscription)) {
      // Validate if the user doesn't has this product as subscription pending to submit in the firebase order
      if (this.existsProductAsPendingSubscription())
        return;

      const inmediatly = this.initiateSubscriptionImmediatly();
      const subscription = this.setUpSubscriptionPayload(this.#variant, inmediatly);

      if (!subscription)
        return this.#notificationService
          .show({
            text: 'You should select a valid frequency and start date for the subscription.',
            type: 'error'
          });

      this.#subscriptionsService
        .create(subscription)
        .pipe(
          tap(() => {

            this.#orderService.getOrder();

            this.showMessageAsModal(
              'Subscription created successfully',
              'Your subscription has been saved! You can manage it anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
            );
          })
        ).subscribe();
    } else {
      const showSuccessMessage = !!this.cardResume().orderId;
      const productToAdd = this.#setUpProductToAdd(isSubscription);
      this.#orderService.addProductToFirebaseOrder(productToAdd, showSuccessMessage);
      this.#justAddedProduct.set(productToAdd);
      if (!this.#signalsStoreService.isInOrderComponent() && !this.shouldShowMobileCard())
        this.#signalsStoreService.showCartPreview.set(true);
      trackAddToCartEvent(this.#dataLayerService, productToAdd);
      event.stopPropagation();
    }

    this.#resetForm();
    if (!this.#shouldShowMobileCard())
      this.flipCardType(this.cardResume().settings?.isSummary || false ? CardTypes.summary : CardTypes.main);
  }

  async flipCardType(cardType: CardTypes, isSignupFlow: boolean = false, event?: Event) {

    if (event)
      event.stopImmediatePropagation();

    switch (cardType) {
      case CardTypes.subscribe:
        // If the user has their order marked as skipped, prevent the full process:
        const hasSkippedOrder = await this.#hasSkippedOrder(true);
        if (hasSkippedOrder) return;

        // If it is a skipped subscription, we should to unskip it:
        if (this.skippedSubscriptionLineId())
          return this.#openModalUnskipLine();

        this.validateProductAndSetUpVariant();
        if (this.#existsProductAsSubscription())
          cardType = CardTypes.main;

        if (isSignupFlow) {
          this.#localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX, this.newQuantity());
          this.#location.replaceState(`/shop/custom-box/${BundleEditionTypes.subscription}/${this.card().product?.id}`);
          window.location.reload();
          return;
        }

        // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
        if (this.#isCustomBundleModificationType(true)) return;

        break;
      case CardTypes.main:
        this.selectedFrequency.set(undefined);
        break;
      default:
        break;
    }
    if (!isSignupFlow) {
      this.cardType.set(cardType);
    }
  }

  #isCustomBundleModificationType(isSubscription: boolean) {
    if (this.card().product?.bundle?.items?.length && environment.config.flows.bundles.modifyType === BundleModifyTypes.outOfOrder) {
      if ((isSubscription && this.existsInOrder().subscription) || (!isSubscription && this.existsInOrder().common)) {

        if (!this.isFromBlog())
          this.#router.navigate([`/shop/custom-box/${isSubscription ? BundleEditionTypes.subscription : BundleEditionTypes.aLaCarte}/${this.card().product.id}`]);

        return true;
      }
    }
    return false;
  }

  #shouldDisabledActionButtonsBase(): boolean {
    return !this.stock().size ||
      (this.product()?.attributes?.length && !this.selectedAttribute()) ||
      (this.product()?.packages?.length && !this.selectedPackage()) ||
      (this.isOutOfStock() || this.isOutOfStockPerQuantity()) ||
      (this.isPreOrderedVariant())
  }

  private shouldDisableActionButtons(): boolean {
    return !!this.#shouldDisabledActionButtonsBase()
  }

  private shouldDisableSubscribeActionButtons(): boolean {
    return !!this.#shouldDisabledActionButtonsBase() ||
      !!(this.existsInOrder().subscription && this.bundleModifyType === this.bundleModifyTypes.default)
  }

  #openModalPreOrderProduct() {
    this.#modalService.openModal(
      ModalContentTypes.PREORDER_PRODUCT,
      {
        title: `Confirm Your Pre-Order`,
        preOrderProduct: {
          ...this.product(),
          _variantData: this.selectedAttributeCardConfig()
        },
      }
    ).closed.subscribe(
      (res: { sendPreOrder: boolean }) => {
        if (res?.sendPreOrder) {
          this.#savePreorderProduct()
        }
      }
    )
  }

  validateProductAndSetUpVariant(): boolean {
    // If the user is suspended, we should prevent for this action:
    if (isSuspendedUser(this.#modalService, this.#localStorageService)) return false;
    // If there is not stock yet, we should prevent for this action:
    if (!this.stock().size) return false;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    this.#bundle = this.product()?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const hasPackages: boolean = !!this.product()?.packages?.length;
    this.#variant = hasPackages ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());
    if (!this.#variant) {
      unableOperationMessage(this.#modalService);

      return false
    }

    if (!this.#bundle && !this.#variant?.stock) {
      unableOperationMessage(this.#modalService);
      return false
    }

    return true;
  }

  private setUpSubscriptionPayload(variant: any, inmediatly: boolean): SubscriptionPayload | null {
    const frequencyId = this.selectedFrequency()?.id || null;
    let startDate = this.selectedDateSubscription() || null;

    if (inmediatly) {
      const sesion: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
      startDate = sesion?.deliveryInfo?.deliveryDate ?? '';
    }

    if (!frequencyId || !startDate)
      return null;

    return {
      frequencyId,
      packageId: this.selectedPackage()?.id || null,
      startDate,
      quantity: this.newQuantity(),
      variantId: variant.id
    };
  }

  #setUpProductToAdd(isSubscription: boolean, isFromSignup = false) {
    let price = this.selectedAttributeCardConfig().price;
    let hasDiscount = this.selectedAttributeCardConfig().hasDiscount;

    if (this.selectedAttributeCardConfig().isSubscribeSave && isSubscription) {
      price = this.selectedAttributeCardConfig().subscribeSavePrice || this.selectedAttributeCardConfig().price;
      hasDiscount = true;
    }

    const selectedFrequency = this.selectedFrequency() || null;
    const selectedDateSubscription = this.selectedDateSubscription() || null;

    return {
      updatedAt: DateTime.now().toMillis(),
      bundle: this.#bundle,
      category: this.product()?.category,
      hasDiscount,
      id: this.product()?.id,
      img: this.product()?.img,
      isASubscription: isSubscription,
      isFromCard: true,
      name: this.product()?.name,
      package: this.selectedPackage() || null,
      presentation: this.product()?.presentation || null,
      price,
      originalPrice: this.selectedAttributeCardConfig().originalPrice ?? this.selectedAttributeCardConfig().price,
      productUrl: this.product()?.productUrl,
      quantity: this.newQuantity(),
      subCategory: this.product()?.subcategory as any,
      subscription: !selectedFrequency ? null : {
        startDate: selectedDateSubscription || null,
        frequency: selectedFrequency
      },
      taxes: this.product()?.taxes,
      totalPrice: this.newQuantity() * (this.product()?.price as number),
      variant: this.#variant,
      isFromSignup,
      isPublishedBundle: this.product()?.isPublishedBundle ?? false,
      producer: this.card().product?.producer || null
    } as Partial<FireBaseProductOrder>
  }

  #checkDislikeProduct(): boolean {
    this.updateLikeDislike();
    let isDislike = false;
    const selectedAttribute = this.selectedAttribute() || null;
    // If the product has attribute or has the variant info:
    if (this.card().product?.attributes?.length && !this.card().product?.variantId) {
      if (selectedAttribute) {
        for (const attr of this.card().product?.attributes || []) {
          const favValues = attr.values.filter((v: any) => v.disliked);
          isDislike = favValues.some((fv: any) => fv.id === selectedAttribute.value.id)
        }
      }
    } else
      isDislike = this.selectedAttributeCardConfig().disliked ?? !!this.card().product?.disliked

    if ((!this.isFirstTime || this.isPreference()) && this.canEmitChangeDislike) {
      const id = this.card().product?.id;
      if (!id) return false;
      this.outClickFavoriteOrDislike
        .emit({
          isDisliked: true,
          value: isDislike,
          id
        });
    }

    return isDislike;
  }

  private existsProductAsPendingSubscription() {
    const products = this.firebaseOrder()?.products?.subscription || [];
    if (!products?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.product()?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in firebase order as subscription with pendind changes
    const existsInOrder = products.some((product: any) => product.variant.id === this.#variant.id);
    if (existsInOrder)
      this.showMessageAsModal('Already subscribed', 'You already have an active subscription to this product. Please delete the existing subscription to add a new one with different characteristics');
    return existsInOrder;
  }

  #existsProductAsSubscription() {
    const products = this.getAllProducts();
    if (!products?.odooOrderSubscriptions?.length || !products?.storedSubscriptions?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.product()?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in order
    const existsInOrder = products.odooOrderSubscriptions.some((product: any) => product.variant.id === this.#variant.id);
    if (existsInOrder) {
      const message = this.setUpProductExistsMessage(true);
      this.showMessageAsModal('Already subscribed', message);
      return true;
    }

    // The product exists in user subscriptions:
    const existsAsSubscription = products.storedSubscriptions.some((subscription: Subscription) => subscription.product.variantId === this.#variant.id);
    if (existsAsSubscription) {
      const message = this.setUpProductExistsMessage(false);
      this.showMessageAsModal('Already subscribed', message);
    }

    return existsAsSubscription;
  }

  private setUpProductExistsMessage(isInOrder: boolean): string {
    const productSource = isInOrder ? 'this product in your order' : 'an active subscription to this product';
    const gotoMessage = isInOrder ? 'checkout' : 'manage subscriptions';
    const routerLink = isInOrder ? '/order' : '/settings/account/subscriptions';
    const aOpenTag = `<a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="${routerLink}">`;

    return `You already have ${productSource}. Please ${aOpenTag}go to ${gotoMessage}</a> to edit your subscription`;
  }

  //#region Preorder Product
  #savePreorderProduct() {
    let preOrderData = null;

    if (!this.validateProductAndSetUpVariant()) return;

    try {
      const product = this.product();
      if (!product) return;
      preOrderData = this.#productCardV2Service.setUpProductForPreOrder({
        product,
        variantId: this.#variant.id,
        packageId: this.selectedPackage()?.id ?? undefined,
        quantity: this.newQuantity()
      });
    } catch (error) {
      return unableOperationMessage(this.#modalService);
    }

    if (!preOrderData) return unableOperationMessage(this.#modalService);

    this.#orderService.savePreOrderProduct(preOrderData).pipe(
      tap(() => this.#afterProductPreordered())
    ).subscribe();

    return false;
  }

  #afterProductPreordered() {
    this.#orderService.getPreOrders().pipe(tap(res => this.preOrders.set(res))).subscribe();

    this.showMessageAsModal(
      'Pre-Order Saved Successfully',
      'Your pre-order has been saved! You can review your pre-orders anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
    );
  }

  //#endregion

  private getAllProducts() {
    return { odooOrderSubscriptions: this.odooOrder()?.products?.subscription ?? [], storedSubscriptions: this.storedSubscriptions() ?? [] }
  }

  private showMessageAsModal(title: string, textContent: string, buttonText: string = 'I understand') {
    this.#modalService.openModal(
      ModalContentTypes.CONFIRMATION,
      {
        title,
        textContent,
        confirmButtonText: 'I understand'
      }
    )
  }

  private setUpStockForProductVariant() {
    // This is for PFW to display unavailable favorite or buy again products as out of stock:
    //#region Unavaible as OOS
    const product: any = this.product();
    if (!product) return false;
    const propertiesToCheck = ['visibleOnEcommerce', 'visibleToSale'];
    if (propertiesToCheck.some(prop => product.hasOwnProperty(prop) && product[prop] === false)) {
      this.hasNotify = product.isAbleToNotify ?? true;
      return true;
    }
    //#endregion
    if (!(product?.checkStock) || product?.bundle?.items?.length) return false;

    if (this.allVariantsOutOfStock) return true;
    const stockMap = this.stock();
    let quantity = this.newQuantity();
    const key = this.#productCardV2Service.getKeyForStockMap(this.productId());
    const pack = this.selectedPackage();
    if (pack?.quantity) quantity = quantity * pack?.quantity;
    const stock = stockMap.get(key);
    this.hasNotify = false;
    if (!stock) return false;

    if (stock.stock <= 0) {
      let oosAfterQuantityChange = (stock.stock - quantity) < stock.limit;
      if (stock.limit === 0 || oosAfterQuantityChange) {
        this.hasNotify = stock.hasNotify;
        return true
      };
    } else {
      const newStock = stock.stock - quantity;
      if (newStock < 0) {
        let oosAfterQuantityChange = newStock < stock.limit;
        if (stock.limit === 0 || oosAfterQuantityChange) {
          this.hasNotify = stock.hasNotify;
          return true;
        }
      }
    }

    return false;
  }

  #setUpSelectAttributeConfig() {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();
    const product = this.product();
    if (!product) return;
    const teaser = this.containsText(product.description?.short || '') ? this.#truncateHtml(product.description?.short, 180) : '';

    const calculateProductPrice = (price: number, subscribeSavePercent: number, packQtty: number = 1): number => {
      const percent = (subscribeSavePercent / 100)
      const discount = +((price * percent).toFixed(2));
      const newPrice = +((price - discount).toFixed(2));
      const newPriceWithPackage = newPrice * packQtty;
      return +(newPriceWithPackage.toFixed(2));
    };

    let variant: any = {
      ...product,
      hasDiscount: !!product.originalPrice,
      teaser,
      image: product.img,
      subscribeSavePrice: product.isSubscribeSave ? calculateProductPrice(+product.price, product.subscribeSavePercent) : product.price
    };

    if (!pack)
      if (!attr || product.variantId) {
        this.outShowDiscountTag.emit(variant.hasDiscount && variant.isOnSale);
        return { ...variant, isALaCarte: variant.isALaCarte ?? true };
      }

    const stockVariant = pack ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (pack?.quantity >= 0) {
      variant.price = pack.price * pack.quantity;
      variant.originalPrice = pack.originalPrice * pack.quantity;
      variant.hasDiscount = pack.originalPrice;
      variant.isOnSale = pack.isOnSale ?? variant.isOnSale;

      const subscribeSavePricePack = product.isSubscribeSave ? calculateProductPrice(pack.price, product.subscribeSavePercent, pack.quantity) : pack.price;
      variant.subscribeSavePrice = subscribeSavePricePack;
    }

    if (attr?.value) {
      const subscribeSavePriceVariant = attr.value.isSubscribeSave ? calculateProductPrice(attr.value.price, attr.value.subscribeSavePercent) : attr.value.price
      variant = {
        variantId: stockVariant?.variantId,
        ...attr.value,
        hasDiscount: !!attr.value?.originalPrice,
        teaser,
        subscribeSavePrice: subscribeSavePriceVariant
      }

    }

    this.outShowDiscountTag
      .emit(variant.hasDiscount && variant.isOnSale);

    this.outSelectedVariant.emit({ ...variant });
    return { ...variant, isALaCarte: variant.isALaCarte ?? true }
  }

  #truncateHtml(html: string, maxLength: number): SafeHtml {
    if (!isPlatformBrowser(this.platformId)) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let charCount = 0;
    const truncateNode = (node: Node): boolean => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        if (charCount + textContent.length > maxLength) {
          const truncatedText = textContent.slice(0, maxLength - charCount) + '... ';
          node.textContent = truncatedText;
          return true;
        } else {
          charCount += textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(node.childNodes);
        for (const childNode of childNodes) {
          if (truncateNode(childNode)) {
            return true;
          }
        }
      }
      return false;
    };

    truncateNode(tempDiv);

    let truncatedHtml = tempDiv.innerHTML;
    if (tempDiv.innerHTML.includes('... </p>'))
      truncatedHtml = tempDiv.innerHTML.replace('... </p>', '... ');
    else
      truncatedHtml = tempDiv.innerHTML;

    truncatedHtml = truncatedHtml.replaceAll('<p>', '<p class="product-card__info">');
    return this.#sanitizer.bypassSecurityTrustHtml(truncatedHtml);
  }

  private getOrderProductsMapByKey(productKeyType: string, mapKey: string, sourceOrder: any,) {
    if (!sourceOrder || !sourceOrder?.products?.[productKeyType]?.length)
      return new Map()

    const orderProductsMap = arrayToMap(mapKey, sourceOrder.products[productKeyType]);

    return orderProductsMap;
  }

  private containsText(htmlString: string) {
    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    if (!doc?.body?.textContent) return false;
    // Get the text content of the parsed document
    const textContent = doc.body.textContent.trim();

    // Check if the text content is empty
    return textContent.length > 0;
  }

  async #hasSkippedOrder(toFlip: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.odooOrder()?.isSkipped) {
        this.#modalService.openModal(ModalContentTypes.CONFIRMATION, {
          title: `You've skipped the week`,
          textContent: `You want to add products to your order but you skipped this week's delivery`,
          confirmButtonText: 'Unskip this week',
        }, { size: 'md' }).closed
          .subscribe((res: { confirm: boolean } | null) => {
            if (!res?.confirm) return resolve(true);

            const args = {
              orderId: this.odooOrder()?.id,
              isSkipping: false,
              donationAmount: 0,
            }

            this.#orderService.skipOrder(args).pipe(delay(toFlip ? 1 : 1000)).subscribe(() => resolve(false))
          })
      } else
        return resolve(false);
    })
  }

  openModalCheckAddress() {
    this.cardType.set(CardTypes.main);
    let data: ModalContentData = {
      title: 'Choose your delivery preference',
      textContent:
        'We need to know your delivery address so we can see what products are available in your area.',
      closeable: false,
    };

    if (this.#activeModal.hasOpenModals()) this.#activeModal.dismissAll();

    this.#modalService.openModal(ModalContentTypes.CHECK_ADDRESS, data, undefined);
  }

  #setUpCarouselStock() {
    combineLatest([this.#stockValidation$, this.#hasStockValidationPerQuantity$]).pipe(
      tap(([stockValidation, hasStockValidationPerQuantity]) => {
        if (!this.card()?.product?.id) return;
        const oos = stockValidation && !hasStockValidationPerQuantity
        this.isOutOfStock.set(oos);
        if (!oos) return;
        const currentHiddenProducts: number[] = [...this.#signalsStoreService.hiddenCarouselProducts()];
        const variantId = this.product()?.variantId;
        if (variantId)
          currentHiddenProducts.push(variantId);
        this.#signalsStoreService.hiddenCarouselProducts.set([...currentHiddenProducts]);
      })
    ).subscribe();
  }

  async addOrSubscribeFromFatherComponent(event: any) {

    const hasSkippedOrder = await this.#hasSkippedOrder();
    if (hasSkippedOrder) return;

    if (!this.stock().size || this.isOutOfStock() || this.isOutOfStockPerQuantity())
      return;

    if (this.selectedAttributeCardConfig().isSubscription) {

      if (this.marketStatus().isOpen && this.selectedAttributeCardConfig().isALaCarte)
        this.addProductToCart(false, event);
      else {
        if (!this.#existsProductAsSubscription()) {
          this.validateProductAndSetUpVariant();
          this.addProductToCart(true, event);
        }
      }

    } else if (this.selectedAttributeCardConfig().isALaCarte)
      this.addProductToCart(false, event);
  }

  #setUpProductDetails(value: ProductCardResume) {
    const { productDetails: product, settings } = value;
    if (!product) return;
    // Set default selected package and attribute:
    if (product.attributes?.length)
      this.selectedAttribute.set(product.attributes[0]);
    if (product.packages?.length)
      this.selectedPackage.set(product.packages[0])

    // Setup pre-order data:
    product.preOrder = this.#productsService.setUpPreorderProductData(product.preOrder || null);

    this.product.set(product);
    const stockMap = arrayToMapMultiKey(['productId', 'attribute.id', 'attribute.value.id'], product.stockInfo);
    this.stock.set(stockMap);
    this.card.set({ ...this.card(), settings: { ...this.card().settings, ...settings }, product });

    if (this.isPreference())
      return this.cardType.set(this.cardTypes.preferences)

    this.#setupProductStock(product, stockMap);

    this.cardType.set(this.cardTypes.main);

    if (product?.bundle?.items?.length) this.#getStockForBundleItems(product.bundle.items);
    this.#setUpCarouselStock();
  }

  #setupProductStock(product: Product, stockMap: Map<number | string, any>) {
    // It's a variant
    if (product?.variantId) {
      if (product?.attributes?.[0])
        this.selectedAttribute.set(product.attributes[0]);
      this.productAttributes = product?.attributes ?? [];
      return
    };

    const attributes = product?.attributes;
    if (attributes) {
      this.productAttributes = attributes;
      const productHasVariants = !!attributes?.length && !!attributes?.[0].values.length;
      if (productHasVariants) {
        let values = attributes[0].values;
        if (!product.allowVariantsWithoutStock) {
          values = values.filter((value: any) => {
            const key = `${product.id}_${attributes[0].id}_${value.id}`;
            const stock = stockMap.get(key);
            if (!stock) return;

            if (stock.stock <= 0) {
              let oosAfterQuantityChange = (stock.stock - this.newQuantity()) < stock.limit;
              if (stock.limit === 0 || oosAfterQuantityChange) {
                return false;
              }
            }
            return true;
          });
        }

        this.productAttributes[0].values = values;
        if (!values.length) {
          this.allVariantsOutOfStock = true;
          this.selectedAttribute.set(null);
          return;
        }
        const attr = attributes[0];
        // Search the first variant that is market like favorite.
        let variant = values.find((v: ProductAttributeValues) => v.fav);
        if (!variant) {

          // Search the first variant that is not market like disliked.
          variant = values.find((x: ProductAttributeValues) => !x.disliked);

          if (!variant)
            variant = values[0];
        }

        const selectedAttribute = {
          id: attr.id,
          name: attr.name,
          value: variant
        };

        this.selectedAttribute.set(selectedAttribute);

      }

      return;
    }

    const packages = product?.packages;
    if (packages?.length) {
      const key = product.id;
      const stock = stockMap.get(key);
      if (!stock) return;

      let packages = product.packages?.filter((value: any) => {
        let oosAfterQuantityChange = (stock.stock - (this.newQuantity() * value.quantity)) < stock.limit;
        if (oosAfterQuantityChange) {
          return false;
        }
        return true;
      }) || []

      product.packages = packages;
      if (!packages.length) {
        this.allVariantsOutOfStock = true;
        this.selectedPackage.set(null);
        return;
      }

      const selectedPackage = packages[0];
      const firstDiscountPackage = packages.find(p => p.isOnSale);
      this.selectedPackage.set(firstDiscountPackage ?? selectedPackage);
    }

  }


  #setUpAvailableQuantities() {
    let available = this.totalStockAvailable();

    if (available > 10) available = 10;

    const quantities = []

    if ((available) <= 10) {
      for (let index = 1; index <= available; index++) {
        quantities.push({ val: index, name: (index === 10 ? '+10' : index) });
      }
    }

    return quantities;
  }

  #setUpTotalStockAvailable() {
    const attr = this.selectedAttribute(); // as a trigger
    const pack = this.selectedPackage();

    const product = this.product();

    if (!(product?.checkStock)) return 100;

    if (product?.bundle?.items.length) {
      // Get stock based on bundle items:
      const mapBundleStock = this.mapBundleStockSignal();
      if (!mapBundleStock.size) return 0;

      const itemsArray = Array.from(mapBundleStock.values());
      const minStockItem = itemsArray.reduce((min, item) =>
        item.stock < min.stock ? item : min
      );

      if (!minStockItem) return 0;

      const bundleItemInfo: BundleItems | any = product.bundle.items.find((i: any) => i.id === minStockItem.variantId);
      if (!bundleItemInfo) return 0;
      return Math.floor(((minStockItem.stock + (minStockItem.limit * -1)) / (bundleItemInfo.packageQty ?? 1)));

    } else {
      const stockVariant = pack ?
        this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
        this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());
      if (!stockVariant) return 0;

      if (pack)
        return (stockVariant.stock / (pack.quantity ?? 1)) + (stockVariant.limit * -1);
      return stockVariant.stock + (stockVariant.limit * -1);
    }
  }

  #cardResumeTransform() {
    const value = this.cardResume();
    const productId = value.product?.id || value.productDetails?.id || null;
    if (productId)
      this.productId.set(productId);
    if (value.productDetails)
      this.#setUpProductDetails(value);
    this.newQuantity.set(1);
    if (value.settings.isPlaceHolder)
      this.cardType.set(this.cardTypes.resume);
    else if (value.settings?.isSummary)
      this.cardType.set(this.cardTypes.summary);
  }

  #existsInOrder() {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();
    const firebaseOrder = this.firebaseOrder();
    const odooOrder = this.odooOrder();

    const defaultRes = { common: false, subscription: false, totalQuantity: 0 };

    const stockVariant = pack ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (!stockVariant) return defaultRes;

    // Check if the product exists as subscription or a la carte in firebse or odoo order:
    const subscriptionFBOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variant.variantId', firebaseOrder)
    const subscriptionOdooOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variantId', odooOrder)
    const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'variant.variantId', firebaseOrder)
    const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'variantId', odooOrder)

    let common = false;
    let subscription = false;
    let totalQuantity = 0;

    if (stockVariant) {
      common = oneTimeFBOrderProductsMap.has(stockVariant.variantId) || oneTimeOdooOrderProductsMap.has(stockVariant.variantId);
      subscription = subscriptionFBOrderProductsMap.has(stockVariant.variantId) || subscriptionOdooOrderProductsMap.has(stockVariant.variantId);
    }

    // One time
    if (oneTimeFBOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += oneTimeFBOrderProductsMap.get(stockVariant.variantId).quantity;
    else if (oneTimeOdooOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += oneTimeOdooOrderProductsMap.get(stockVariant.variantId).quantity;

    // Subscriptions
    if (subscriptionFBOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += subscriptionFBOrderProductsMap.get(stockVariant.variantId).quantity;
    else if (subscriptionOdooOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += subscriptionOdooOrderProductsMap.get(stockVariant.variantId).quantity;

    return { common, subscription, totalQuantity }
  }

  #setUpIsPreOrderedVariant() {
    const preOrderedProduct = this.selectedAttributeCardConfig()?.isPreOrdered ?? false;
    const preOrders = this.preOrders();
    if (preOrderedProduct) return true;
    if (!preOrders.length) return false;
    if (!this.validateProductAndSetUpVariant()) return false;
    const variantId = this.#variant.id;
    return preOrders.some(o => o.variant.id === variantId);
  }

  #getStockForBundleItems(items: BundleItems[]) {
    const productsId = items.map((i: any) => {
      return i.productId;
    });

    this.mapBundleStockSignal.set(new Map());

    this.#stockService.getStandAloneStockByProductsId(productsId).pipe(
      tap(res => {
        const mapBundleStock = arrayToMapMultiKey(['variantId'], res);
        if (!mapBundleStock.size) return;

        this.mapBundleStockSignal.set(mapBundleStock);
      })
    ).subscribe();
  }

  #isBundleOutOfStock() {
    const productQtty = this.newQuantity() ?? 1;
    const product = this.product();
    if (!product) return;
    const items: BundleItems[] = product?.bundle?.items || [];
    const checkStock = product.checkStock ?? false;
    if (!items.length || !checkStock) return false;

    const mapBundleStock = this.mapBundleStockSignal();
    if (!mapBundleStock.size) return false;

    let response = false;
    let hasNotify = false;

    for (const item of items) {
      const { id: variantId, quantity, packageQty } = item;
      const stock = mapBundleStock.get(variantId);
      if (!stock) {
        response = true;
        hasNotify = true;
        break;
      }

      if (stock.stock <= 0) {
        const qtty = quantity * (packageQty ?? 1) * productQtty;
        let oosAfterQuantityChange = (stock.stock - qtty) < stock.limit;
        if (stock.limit === 0 || oosAfterQuantityChange) {
          response = true;
          hasNotify = stock.hasNotify;
          break;
        }
      }
    }

    this.hasNotify = hasNotify;
    return response;
  }

  openModalWhatsInside(event: any) {
    event.stopPropagation();
    const product = this.card().product;
    if (!product?.bundle) return;
    const startDate = formatDateToReadableString(product.bundle.startDate).mmddyyyyFormat;
    const endDate = formatDateToReadableString(product.bundle.endDate).mmddyyyyFormat;
    this.#modalService.openModal(ModalContentTypes.BOX_DETAIL, {
      title: product.name,
      closeable: true,
      fullScreenOnMobile: true,
      boxDetail: {
        isSubscription: this.card().product?.isSubscription,
        existsInOrder: this.card().product?.isSubscription ? this.existsInOrder().subscription : this.existsInOrder().common,
        startDate,
        endDate,
        publishedBundles: product?.bundle?.publishedBundles,
        detail: this.card().product?.bundle?.items?.map((e: any) => {
          return {
            img: e.img,
            productName: e.name,
            quantity: e.quantity,
            packageName: e.packageName || e.display || '',
            producer: e.producerName || '',
            price: e.price,
          }
        })
      }
    } as ModalContentData).closed.subscribe(
      (res: { addToCart: boolean }) => {
        if (res.addToCart) {

          if (this.subscribeAndcustomizeBox()) {
            this.shouldShowMobileCard() ? this.subscribeProductMobile() : this.flipCardType(this.cardTypes.subscribe);
            return;
          }

          if (this.card().product?.isSubscription) {
            if (environment.config.flows.bundles.modifyType === BundleModifyTypes.default) {
              this.shouldShowMobileCard() ? this.subscribeProductMobile() : this.flipCardType(this.cardTypes.subscribe)
            } else {
              this.#router.navigate(['/shop/custom-box/' + BundleEditionTypes.subscription + '/' + this.card().product?.id])
            }
          } else {
            this.addProductToCart(false, event);
          }
        }
      }
    )
  }

  purchaseGiftCardClicked() {
    this.onPurchaseGiftCardClicked.emit();
  }

  prevDislikeProduct() {

    if (this.isDisabledOverProductActions())
      return;

    if (this.isDislike())
      return this.#handleDislikeChange();

    if (this.#signalsStoreService.userPreferences().dontShowAgainPrevDislike?.value)
      return this.#handleDislikeChange();

    this.#modalService
      .openModal(ModalContentTypes.DISLIKE_PRODUCT_CONFIRMATION, {
        title: 'Are you sure?',
        textContent: `You are about to dislike a product. We will do our best to never send you a product that you don't like. You can always adjust these settings later in your profile.`
      })
      .closed
      .subscribe((res: { isConfirm: boolean }) => {
        if (!res.isConfirm) return;
        this.#handleDislikeChange();
      });
  }

  #shouldDisableOverProductActions() {
    return !this.stock()?.size ||
      (this.card().product?.attributes?.length && !this.selectedAttribute()) ||
      (this.card().product?.packages?.length && !this.selectedPackage()) ||
      (this.isPreOrderedVariant())
  }

  #handleDislikeChange() {
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stock()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.card().product?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const variant = this.card().product?.packages?.length ? this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (!variant)
      return unableOperationMessage(this.#modalService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.#modalService);

    this.isFirstTime = false;

    if (!this.isDislike())
      this.#productsService
        .setDislike(variant.id, !this.isPreference())
        .subscribe(() => {
          this.#updateLikeDislikeProperty(false, true);
          this.#updateDislikedItemsFirebase(variant.id, true, false);
          this.#ifAllVariantsDisliked();
        });
    else
      this.#productsService
        .removeDislike(variant.id, !this.isPreference())
        .subscribe(() => {
          this.#updateLikeDislikeProperty(false, false);
          this.#updateDislikedItemsFirebase(variant.id, false, false);
        });
  }

  #ifAllVariantsDisliked() {

    const product = this.card().product;

    if (product.variantId || !product?.attributes)
      return this.#sendProductToBottom();

    const attributes = product?.attributes;

    if (!(attributes?.length && attributes?.[0].values.length))
      return;

    const allDisliked = attributes[0].values.every((x: any) => x.disliked);

    if (allDisliked)
      return this.#sendProductToBottom();
  }

  #sendProductToBottom() {

    this.#productsService
      .productsSignal
      .update(val => {

        const index = val.findIndex(x => x.id === this.card().product.id);

        if (index === -1)
          return val;

        // Search if there is more than 1 product for this category.

        const productsInCategory = val
          .filter(x => x.subcategory.id === val[index].subcategory.id)
          .length;

        if (productsInCategory < 2)
          return val;

        const product = val.splice(index, 1);

        // Search the index of the last product of this category

        const lastIndex = val
          .map(x => x.subcategory.id)
          .lastIndexOf(product[0].subcategory.id);

        if (lastIndex !== -1)
          val.splice(lastIndex + 1, 0, product[0]);

        return [...val];
      });
  }


  #updateLikeDislikeProperty(isFavorite: boolean, value: boolean) {

    const selectedAttribute = this.selectedAttribute() || null;

    if (this.card().product?.attributes?.length && !this.card().product?.variantId && selectedAttribute) {
      for (const attr of this.card().product?.attributes || []) {

        const item = attr.values
          .find((v: any) => v.id === selectedAttribute.value.id);

        if (!item)
          continue;

        item[isFavorite ? 'fav' : 'disliked'] = value;
        if (value)
          item[isFavorite ? 'disliked' : 'fav'] = false;
      }
    }

    this.card().product[isFavorite ? 'fav' : 'disliked'] = value;
    if (value)
      this.card().product[isFavorite ? 'disliked' : 'fav'] = false;

    this.selectedAttributeCardConfig()[isFavorite ? 'fav' : 'disliked'] = value;
    if (value)
      this.selectedAttributeCardConfig()[isFavorite ? 'disliked' : 'fav'] = false;

    this.canEmitChangeLike = false;
    this.canEmitChangeDislike = false;

    if (isFavorite)
      this.canEmitChangeLike = true;
    else
      this.canEmitChangeDislike = true;

    this.updateLikeDislike.update(value => !value);
  }

  //#endregion

  //#region Update like/dislike property in firebase

  #updateDislikedItemsFirebase(productId: number, disliked: boolean, fav: boolean) {

    const session = this.#signalsStoreService.sessionSignal();
    if (!session) return;

    const userId = session.accountInfo.id.toString();

    this.#firebaseCrudService
      .getByIdNoValueChange(FIREBASE_COLLECTIONS.ORDERS, userId)
      .pipe(
        tap(async res => {

          this.#updateDislikeFirebase(res?.orderProducts?.products?.subscription ?? [], productId, disliked, fav);
          this.#updateDislikeFirebase(res?.orderProducts?.products?.common ?? [], productId, disliked, fav);

          await this.#firebaseCrudService
            .update(FIREBASE_COLLECTIONS.ORDERS, userId, res);
        })
      )
      .subscribe();
  }

  #updateDislikeFirebase(products: any[], productId: number, disliked: boolean, fav: boolean) {
    for (const product of products) {
      if (product.bundle.items.length) {
        const productIndex = product.bundle.items.findIndex((x: any) => x.id === productId);
        if (productIndex !== -1) {
          product.bundle.items[productIndex].disliked = disliked;
          product.bundle.items[productIndex].fav = fav;
        }
      } else if (product.id === productId) {
        product.disliked = disliked;
        product.fav = fav;
      }
    }
  }

  //#endregion

  //#region Skipped subscription
  #setUpIsSkippedSubscriptionVariant() {
    const subscriptions = this.#signalsStoreService.subscriptions();
    const selectedAttribute = this.selectedAttributeCardConfig();
    return subscriptions?.find((sub) => ((selectedAttribute.variantId && selectedAttribute.variantId === sub.product.variantId) || (sub.product.id === selectedAttribute.id)) && sub.skipDate)?.id || null;
  }

  #openModalUnskipLine() {
    this.#modalService.openModal(ModalContentTypes.CONFIRMATION, {
      title: `You've skipped this product for this week`,
      textContent: `Do you want to unskip this product for this week?`,
      confirmButtonText: 'Unskip product',
    }, { size: 'md' }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (res?.confirm) this.#unskipSubscription();
      })
  }

  #unskipSubscription() {
    const susbcriptionLineId = this.skippedSubscriptionLineId();
    if (!susbcriptionLineId) return
    this.#subscriptionsService.update(susbcriptionLineId, { unskip: true }, true).pipe(
      tap(() => this.#orderService.getOrder())
    ).subscribe()
  }

  //#endregion

  async continue() {
    await this.#subscribeProductFromSignup();
    this.farmBoxCard.emit(this.card().product);
  }

  #subscribeProductFromSignup() {
    return new Promise<void>((resolve, reject) => {

      try {
        const productToAdd = this.#setUpProductToAdd(true, true);
        this.#orderService.addProductToFirebaseOrder(productToAdd, false);
        if (!this.shouldShowMobileCard())
          this.#justAddedProduct.set(productToAdd);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  #checkFavProduct() {
    this.updateLikeDislike();
    let isFavorite = false;
    const selectedAttribute = this.selectedAttribute() || null;
    // If the product has attribute or has the variant info:
    if (this.card().product?.attributes?.length && !this.card().product?.variantId) {
      if (selectedAttribute) {
        for (const attr of this.card().product?.attributes || []) {
          const favValues = attr.values.filter((v: any) => v.fav);
          isFavorite = favValues.some((fv: any) => fv.id === selectedAttribute.value.id)
        }
      }
    } else
      isFavorite = this.selectedAttributeCardConfig().fav ?? !!this.card().product?.fav

    if ((!this.isFirstTime || this.isPreference()) && this.canEmitChangeLike) {
      this.outClickFavoriteOrDislike
        .emit({
          isDisliked: false,
          value: isFavorite,
          id: this.card().product?.id
        });
    }

    return isFavorite;
  }

  handleFavoriteChange() {
    if (this.isDisabledActionButtons() || this.isDisabledOverProductActions()) return;
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stock()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.card().product?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const variant = this.card().product?.packages?.length ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (!variant)
      return unableOperationMessage(this.#modalService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.#modalService);

    if (!this.isFavorite())
      this.#productsService
        .setFavorite(variant.id, !this.isPreference())
        .subscribe(() => {
          this.#updateLikeDislikeProperty(true, true);
          this.#updateDislikedItemsFirebase(variant.id, false, true);
        });
    else
      this.#productsService
        .removeFavorite(variant.id, !this.isPreference())
        .subscribe(() => {
          this.#updateLikeDislikeProperty(true, false);
          this.#updateDislikedItemsFirebase(variant.id, false, false);
        });
  }

  //#region MOBILE:

  #shouldShowMobileCard() {
    const isMobile = this.isMobile();
    const isShop = this.isInShopComponent();
    const isSignup = this.isSignupFlow();
    const isSubAndCustom = this.subscribeAndcustomizeBox();
    const isFromFav = this.isFromFavPage();
    const isPref = this.isPreference();

    if (!isMobile) return false;

    return isShop || isSignup || isSubAndCustom || isFromFav || isPref;
  }

  async subscribeProductMobile() {
    const hasSkippedOrder = await this.#hasSkippedOrder(true);
    if (hasSkippedOrder) return;

    // If it is a skipped subscription, we should to unskip it:
    if (this.skippedSubscriptionLineId())
      return this.#openModalUnskipLine();

    this.validateProductAndSetUpVariant();
    if (this.#existsProductAsSubscription()) return;

    // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
    if (this.#isCustomBundleModificationType(true)) return;

    this.#modalService
      .openModal(ModalContentTypes.PRODUCT_SUBSCRIPTION, {
        title: 'Product subscription',
        productSubscription: {
          subscribeAndcustomizeBox: this.subscribeAndcustomizeBox(),
          minDate: this.minDate(),
          deliveryDay: this.deliveryDay()
        }
      }).closed
      .subscribe(async result => {

        if (!result || !result.hasOwnProperty('isCancel') || result.isCancel) {
          this.#resetForm();
          return;
        }

        const {
          initiateSubscriptionImmediatly,
          selectedDateSubscription,
          selectedFrequency,
          event
        } = result;

        this.initiateSubscriptionImmediatly.set(initiateSubscriptionImmediatly);
        this.selectedDateSubscription.set(selectedDateSubscription);
        this.selectedFrequency.set(selectedFrequency);

        if (result.isContinue) {
          await this.#subscribeProductFromSignup();
          this.farmBoxCard.emit(this.card().product);
          return;
        }

        this.addProductToCart(true, event);
      });
  }

  #resetForm() {
    this.initiateSubscriptionImmediatly.set(true);
    this.selectedDateSubscription.set(undefined);
    this.selectedFrequency.set(undefined);
  }
  //#endregion

  #trackPackOrAttrItemViewDataLayerEvent(data: { value?: ProductAttributeValues, pack?: ProductPackages }) {
    const { value, pack } = data;
    const product = this.card().product;
    let price = +product.price;
    let originalPrice = product.originalPrice || +product.price;
    // If the product has attributes:
    if (value) {
      price = value.price;
      originalPrice = value.originalPrice || value.price;
    } else if (pack) {
      price = pack.price * pack.quantity;
      originalPrice = (pack.originalPrice || pack.price) * pack.quantity;
    }

    price = +(price.toFixed(2))
    originalPrice = +(originalPrice.toFixed(2))

    const discount = originalPrice ? +(originalPrice - price).toFixed(2) : 0;
    this.#dataLayerService.trackViewItemEvent({
      ecommerce: {
        currency: DATA_LAYER_ECOMMERCE_CURRENCY_OPTIONS.usd,
        value: price,
        items: [{
          item_id: product.id.toString(),
          item_name: product.name,
          price,
          discount,
          item_category: (product.category?.name || '').replace(/&/g, 'and'),
          item_category2: (product.subcategory?.name || '').replace(/&/g, 'and'),
          item_brand: product.producer?.name || '',
          quantity: 1
        }]
      }
    })
  }

}
